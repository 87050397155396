import {
  getCabinetMenu,
  getCabinetMenuSuccess,
  getCabinetMenuFailure,
} from '../store/cabinetMenuReducer';
import { api } from '../api/httpClient';

export const fetchCabinetMenu = (userId) => {
  return function (dispatch) {
    dispatch(getCabinetMenu());

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/content/rest_api/v1/users/${userId}/cabinet_menu/`
      )
      .then((response) => {
        dispatch(getCabinetMenuSuccess(response.data));
      })
      .catch(() => {
        dispatch(getCabinetMenuFailure());
      });
  };
};

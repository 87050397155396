import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAuthData } from '../asyncActions/getAuthData';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const cabinetMenu = useSelector((state) => state.cabinetMenu.cabinet_menu);
  const [menu, setMenu] = useState([]);

  const menuAdItem = [
    {
      title: 'Active Directory',
      name: '/',
      url: '/users',
      children: [
        {
          title: 'Домены',
          url: 'domains',
        },
        {
          title: 'Пользователи',
          url: 'users',
        },
        {
          title: 'Коллекции',
          url: 'collections',
        },
      ],
    },
  ];

  useEffect(() => {
    const modifiedMenu = cabinetMenu.map((item) => {
      return {
        ...item,
        url: `${process.env.REACT_APP_BASE_URL}${item.url}`,
      };
    });

    setMenu(modifiedMenu);
  }, [cabinetMenu]);

  useEffect(() => {
    dispatch(fetchAuthData());
  }, [dispatch]);

  return (
    <AuthContext.Provider value={{ menu, menuAdItem }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React from 'react';
import { Grid, Box, Paper } from '@mui/material';

import CreateDomain from './CreateDomain';
import ConfigurateAd from './ConfigurateAd';
import DomainsList from './DomainsList';

import Notification from '../UI/notification/Notification';

import './Domains.scss';

const Domains = () => {
  return (
    <Box className="domains" sx={{ position: 'relative' }}>
      <Paper sx={{ mb: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CreateDomain />
          </Grid>
          <Grid item xs={12} md={6}>
            <ConfigurateAd />
          </Grid>
        </Grid>
      </Paper>

      <DomainsList />
      <Notification />
    </Box>
  );
};

export default Domains;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, Stack } from '@mui/material';

import Checkbox from '../checkbox/Checkbox';
import Search from './Search';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { checkedCollectionItems } from '../../store/collectionsReducer';
import { reduceUsersToTheirInitialSize } from '../../store/usersReducer';
import findUsersInOtherGroups from '../../utils/findUsersInOtherGroups';

import './Collections.scss';

import { fetchUsersOnScroll } from '../../asyncActions/getUsersOnScroll';

import icons from '../../icons/collections/sprite.svg';

const GroupIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <use href={`${icons}#group`} className="group-icon" />
  </svg>
);

const PersonIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <use href={`${icons}#user`} className="user-icon" />
  </svg>
);

const CollectionsConfigurate = ({ collectionName, collectionUsers }) => {
  // Total pages number comes from an api endpoint called 'ad_users_with_profiles'
  const totalPagesCount = useSelector((state) => state.users.totalPagesCount);

  const dispatch = useDispatch();
  const DOMAINS = useSelector((state) => state.domains.domains_list);
  const GROUPS = useSelector((state) => state.groups.groups_list);
  const USERS = useSelector((state) => state.users.users_list);
  const filteredUsersFromApi = useSelector(
    (state) => state.users.filteredUsersFromApi
  );
  const isAuthData = useSelector((state) => state.auth.auth);

  const observer = useRef();

  const treeIcons = {
    check: <CheckOutlinedIcon color="primary" fontSize="large" />,
    uncheck: <CheckBoxOutlineBlankIcon sx={{ color: '#fafcff' }} />,
    expandClose: <KeyboardArrowRightIcon sx={{ color: '#c2cfe0' }} />,
    expandOpen: <KeyboardArrowDownIcon sx={{ color: '#c2cfe0' }} />,
    halfCheck: <RemoveOutlinedIcon color="primary" />,
  };

  const [checked, setChecked] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [result, setIsResult] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [nodesFiltered, setNodesFiltered] = useState([]);
  const [lastChildren, setLastChildren] = useState([]);
  const [breadcrumbs, setIsBreadcrumbs] = useState('');
  const [selectedDomain, setSelectedDomain] = useState({});
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [isGroupSelected, setSelectedGroup] = useState(false);
  const [pageCount, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rawGroupId, setRawGroupId] = useState('');
  const [groupFilterText, setGroupFilterText] = useState('');

  // const [selectAllState, setSelectAllState] = useState(false);
  // This selectAllObject is meant to keep current selected group
  // with its children's length and an array for selected ids
  // const [selectAllObject, setSelectAllObject] = useState({});
  // const [currentNode, setCurrentNode] = useState({});

  // This will roll back users array to its initial size
  // otherewise there'll be an error with duplicating ids
  useEffect(() => {
    dispatch(reduceUsersToTheirInitialSize());
  }, [dispatch]);

  //// Start Infinite scroll section

  useEffect(() => {
    if (pageCount > 1 && pageCount <= totalPagesCount) {
      setLoading(true);

      dispatch(fetchUsersOnScroll(isAuthData.user_id, pageCount));

      setLoading(false);
    }
  }, [pageCount, totalPagesCount, isAuthData.user_id, dispatch]);

  const lastUserElementRef = useCallback(
    (node) => {
      if (loading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((p) => p + 1); // trigger loading of new posts by changing pageCount number
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [loading]
  );

  // This effect looks for a selected group that will be used
  // to load users in infinite scroll
  useEffect(() => {
    const filterTreeToFindSelectedGroup = () => {
      if (filterText) {
        return result.reduce(filterNodes, []);
      }
    };

    const node = filterTreeToFindSelectedGroup();

    if (node) {
      setLastChildren(node[0].children[0].children);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  //// End Infinite scroll section

  const setAsArr = Object.keys(checked);
  // const currentGroup = selectAllObject[currentNode.label];

  // This effect renders previously selected users in created collections
  useEffect(() => {
    collectionUsers &&
      GROUPS.forEach((group) => {
        //checking for a match of selected users in the tree
        const filterSelected = group.ad_users.filter((element) =>
          collectionUsers.includes(element)
        );

        filterSelected.forEach((userId) => {
          // const key = `{\"ad_users\":\"${DOMAINS.map((domain) =>
          //   domain.id === group.domain ? 'd' + domain.id : null
          // )}-g${
          //   group.parent !== null ? group.parent + '-g' + group.id : group.id
          // }-u${USERS.map((user) =>
          //   user.id === userId ? user.id : null
          // )}\"}`.replace(/[\s,%]/g, '');

          const key = JSON.stringify({
            ad_users: `d${group.domain}-g${group.id}-u${userId}`,
          });

          setChecked((c) => ({
            ...c,
            [key]: { checked: false },
          }));
        });
      });
  }, [GROUPS, collectionUsers]);

  useEffect(() => {
    setNodesFiltered(result);
  }, [result]);

  useEffect(() => {
    const checkedList = [];

    setAsArr.forEach((check) => {
      if (check.includes('ad_users')) {
        const parsedId = +JSON.parse(check).ad_users.split('u')[1];

        // Sometimes a parsed id is equal to 0, ids create in 'isData' effect
        if (parsedId > 0) {
          checkedList.push(parsedId);
        }
      }
    });

    const resultChecked = checkedList.filter((elem, index, self) => {
      return index === self.indexOf(elem);
    });

    dispatch(checkedCollectionItems(resultChecked));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, dispatch]);

  useEffect(() => {
    const users =
      filteredUsersFromApi.length > 0 ? filteredUsersFromApi : USERS;

    const isData = DOMAINS.map((domain) => ({
      value: JSON.stringify({
        id: domain.id,
      }),
      title: `${domain.name}`,
      label: domain.name,
      children: GROUPS.filter(
        (group) => group.domain === domain.id && group.parent === null
      ).map((group) => ({
        value: JSON.stringify({
          groupId: `d${domain.id}-g${group.id}`,
        }),
        title: `${domain.name}/${group.name}`,
        label: group.name,
        children: [
          ...GROUPS.filter((child) => group.id === child.parent).map(
            (child_f) => ({
              value: JSON.stringify({
                groupId: `d${domain.id}-g${group.id}-g${child_f.id}`,
              }),

              title: `${domain.name}/${group.name}/${child_f.name}`,
              label: child_f.name,
              children: users
                .filter((user) => child_f.ad_users.indexOf(user.id) !== -1)
                .map((user_f) => ({
                  value: JSON.stringify({
                    ad_users: `d${domain.id}-g${group.id}-g${child_f.id}-u${user_f.id}`,
                  }),
                  title: `${domain.name}/${group.name}/${child_f.name}/${user_f.name}`,
                  label: user_f.name,
                })),
            })
          ),

          ...users
            .filter((user) => group.ad_users.indexOf(user.id) !== -1)
            .map((user_g) => ({
              value: JSON.stringify({
                ad_users: `d${domain.id}-g${group.id}-u${user_g.id}`,
              }),
              title: `${domain.name}/${group.name}/${user_g.name}`,
              label: user_g.name,
            })),
        ],
      })),
    }));

    setIsResult(isData);
  }, [DOMAINS, GROUPS, USERS, filteredUsersFromApi]);

  // Tree filtering section

  const filterNodes = (filtered, node) => {
    const children = (node.children || []).reduce(filterNodes, []);

    if (
      node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
        -1 ||
      children.length
    ) {
      filtered.push({ ...node, ...(children.length && { children }) });
    }

    return filtered;
  };

  const filterTree = () => {
    if (!filterText) {
      setNodesFiltered(result);

      return;
    }

    setNodesFiltered(result.reduce(filterNodes, []));
  };

  const onFilterHandler = (e) => {
    setFilterText(e.target.value);

    e.target.value ? filterTree() : setNodesFiltered(result);
  };

  const onGroupFilterHandler = (e) => {
    setGroupFilterText(e.target.value);
  };

  /////

  const onCheckHandler = (event, itemId) => {
    if (itemId.includes('groupId')) {
      setSelectedGroup(event.target.checked);

      setSelectedGroupId(itemId);
      setChecked({ ...checked, [itemId]: null });

      return;
    }

    // When unchecking a user in a group, this user should be unchecked in other groups as well.
    // Since in an other groups the same user has different id
    // for example '{"ad_users":"d1-g6-u4"}' and '{"ad_users":"d1-g5-u4"}'.
    const parsedItem = JSON.parse(itemId).ad_users;
    // We need to find it by third part of its id - 'u4'.
    const parsedId = parsedItem.split('u')[1];

    if (!event.target.checked) {
      setChecked((prevState) => {
        const copy = { ...prevState };
        delete copy[itemId];

        // Here we delete unchecked user in other groups
        for (let id in copy) {
          const userId = `u${parsedId}`;

          if (id.includes(userId)) {
            delete copy[id];
          }
        }

        return copy;
      });

      // currentGroup.ids.delete(itemId);

      // setSelectAllObject((prevState) => ({
      //   ...prevState,
      //   [currentNode.label]: currentGroup,
      // }));

      return;
    }

    const foundUsers = findUsersInOtherGroups(GROUPS, parsedId);

    setChecked({ ...checked, ...foundUsers });
    // currentGroup.ids.add(itemId);

    // setSelectAllObject((prevState) => ({
    //   ...prevState,
    //   // Here we need to update selectAll object with newly selected users with the same group name
    //   [currentNode.label]: currentGroup,
    // }));
  };

  useEffect(() => {
    // This check only makes sure that some domain is selected
    if (selectedDomain.hasOwnProperty('value')) {
      // If group is unselected, firstly remove its id from checked state
      if (!isGroupSelected) {
        setChecked((c) => {
          const copy = { ...c };

          delete copy[selectedGroupId];

          return copy;
        });

        // Then remove from checked state all ids of its children
        selectedDomain.children.forEach((child) => {
          // Find a match in selected domain's children with selected group
          if (child.value === selectedGroupId) {
            child.children.forEach((item) => {
              const parsedItem = JSON.parse(item.value).ad_users;
              // We need to find it by third part of its id - 'u4'.
              const parsedId = parsedItem.split('u')[1];

              // Then update state of checked items with selected group children's ids
              setChecked((c) => {
                const copy = { ...c };

                // Here we delete unchecked user in other groups
                for (let id in copy) {
                  const userId = `u${parsedId}`;

                  if (id.includes(userId)) {
                    delete copy[id];
                  }
                }

                // delete copy[item.value];

                return copy;
              });
            });
          }
        });

        return;
      }

      selectedDomain.children.forEach((child) => {
        // Find a match with in selected domain's children with selected group
        if (child.value === selectedGroupId) {
          child.children.forEach((item) => {
            const parsedItem = JSON.parse(item.value).ad_users;
            // We need to find it by third part of its id - 'u4'.
            const parsedId = parsedItem.split('u')[1];

            const foundUsers = findUsersInOtherGroups(GROUPS, parsedId);

            // Then update state of checked items with selected group children's ids
            setChecked((c) => ({ ...c, ...foundUsers }));
          });
        }
      });
    }
  }, [selectedDomain, selectedGroupId, isGroupSelected, GROUPS]);

  const selectedDomainHandler = (node) => {
    if (!node.isChild) {
      setSelectedDomain(node);
    }
  };

  return (
    <div className="collections-form-configurate-wrapper">
      <div className="collections-form-configurate">
        <Grid
          container
          className="collections-search-bar"
          sx={{ alignItems: 'center', mb: 3 }}
        >
          <Grid item xs={8} className="collections-breadcrumbs">
            {`${
              collectionName ? collectionName : 'Collection name'
            } / ${breadcrumbs}`}
          </Grid>
          <Grid
            item
            xs="auto"
            sx={{
              ml: 'auto',
              pl: 2,
              pr: 1,
              borderLeft: '1px solid #ebeff2',
              borderRight: '1px solid #ebeff2',
            }}
          >
            <Search
              value={filterText}
              placeholder="Поиск"
              onChange={onFilterHandler}
            />
          </Grid>
        </Grid>
        <div className="collections-form-configurate-items">
          <div className="collections-form-configurate-items-tree">
            <CheckboxTree
              nodes={nodesFiltered}
              checked={setAsArr}
              expanded={expanded}
              disabled
              onExpand={setExpanded}
              showNodeIcon={false}
              expandOnClick
              onClick={(expanded) => {
                // setSelectAllHandler(expanded);
                selectedDomainHandler(expanded);

                // Store a raw number of group id. Only a number 6 will be saved from {groupId: 'd1-g6'}
                setRawGroupId(
                  JSON.parse(expanded.value).groupId?.split('-g')[1]
                );

                setIsBreadcrumbs(
                  expanded.parent.title !== undefined
                    ? expanded.parent.title
                    : ''
                );

                // setCurrentGroupName(expanded.label);

                setFilterText(expanded.label);
                // setSelectedGroupLabel(expanded.label);

                if (expanded.children) {
                  setLastChildren(expanded.children);

                  // expanded.children.forEach((child) => {
                  //   console.log(child.hasOwnProperty('children'));

                  //   if (child.hasOwnProperty('children')) {
                  //     // setLastChildren([]);
                  //     setLastChildren(expanded.children);
                  //   }
                  // });
                }
              }}
              icons={treeIcons}
            />
          </div>

          <Stack sx={{ overflowY: 'auto' }}>
            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                padding: '16px 16px 4px 16px',
              }}
            >
              <Grid item xs={6}>
                <Search
                  value={groupFilterText}
                  userId={isAuthData.user_id}
                  groupId={rawGroupId}
                  placeholder="Поиск по группе"
                  onChange={onGroupFilterHandler}
                  sx={{ pl: 1, border: '1px solid #e4e7ec' }}
                />

                {/* <Checkbox
                  disabled={!lastChildren.length}
                  label="Select all"
                  checked={selectAllState}
                  onChange={selectAllHandler}
                /> */}
              </Grid>
              <Grid item xs={6} className="users-counter">
                {setAsArr.length} Users
              </Grid>
            </Grid>

            {lastChildren.length ? (
              <Box>
                {lastChildren.map((child, index) => {
                  const isNodeUser = child.value.includes('ad_users');
                  const icon = isNodeUser ? <PersonIcon /> : <GroupIcon />;

                  let isGroupChecked = false;

                  // Here make sure that current child is a group with children
                  if (child.children && child.children.length > 0) {
                    // Run check to make sure that every item from group's children has 'checked' state
                    // which means that group should be checked
                    isGroupChecked = child.children.every((item) => {
                      return setAsArr.includes(item.value);
                    });
                  }

                  // lastUserElementRef should be called only on users and not on groups

                  const ref =
                    lastChildren.length === index + 1 &&
                    isNodeUser &&
                    lastChildren.length > 40
                      ? lastUserElementRef
                      : null;

                  return (
                    <Box key={child.value} sx={{ p: '12px 16px' }} ref={ref}>
                      <Checkbox
                        label={
                          <>
                            {icon}
                            {child.label}
                          </>
                        }
                        checked={
                          isGroupChecked
                            ? isGroupChecked
                            : setAsArr.includes(child.value)
                        }
                        onChange={(event) => onCheckHandler(event, child.value)}
                      />
                    </Box>
                  );
                })}
              </Box>
            ) : null}
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default CollectionsConfigurate;

// const selectAllHandler = (event) => {
//   setSelectAllState(event.target.checked);
//   const allIds = {};

//   if (event.target.checked) {
//     // When 'Select all' is checked, add all users ids to 'checked' state
//     // Last children are users in right column
//     lastChildren.forEach((child) => {
//       allIds[child.value] = null;
//       // Also add all users ids to current state of 'Select all'
//       currentGroup.ids.add(child.value);
//     });
//     // Store users ids to 'checked' state
//     setChecked((prevState) => ({ ...prevState, ...allIds }));
//     // Store users ids to state of 'Select all'
//     setSelectAllObject((prevState) => ({
//       ...prevState,
//       [currentNode.label]: currentGroup,
//     }));
//     return;
//   }
//   setChecked(allIds);
//   // Clear current group's ids when 'Select all' unchecked
//   currentGroup.ids = new Set();
//   setSelectAllObject((prevState) => ({
//     ...prevState,
//     [currentNode.label]: currentGroup,
//   }));
// };

// const setSelectAllHandler = (node) => {
//   if (node.isChild) {
//     setCurrentNode(node);
//   }
// };

// useEffect(() => {
//   // Current group can be undefined during first render
//   if (currentGroup) {
//     setSelectAllState(currentGroup.childrenLength === currentGroup.ids.size);
//   }
// }, [selectAllObject, currentNode, checked, currentGroup]);

// useEffect(() => {
//   const currentObject = {
//     [currentNode.label]: {
//       childrenLength: currentNode?.children?.length,
//       ids: currentGroup ? currentGroup.ids : new Set(),
//     },
//   };

//   // Update object in order to render the state of 'Select all' control
//   // setSelectAllObject((prevState) => ({ ...prevState, ...currentObject }));
// }, [currentNode, setSelectAllObject]);

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { TableRow, IconButton, Typography } from '@mui/material';

import { domainId } from '../../store/domainsReducer';
import ModalContext from '../../contexts/modal-context';

import { TableBodyCell } from '../table-cells/TableCells';

import Delete from '../../icons/Delete';
import Edit from '../../icons/Edit';

import './Domains.scss';
import appStyles from '../app/App.module.scss';

const DomainsListItem = ({ id, domain, groups, users }) => {
  const dispatch = useDispatch();
  const {
    isShowRemoveModal,
    setIsShowRemoveModal,
    isShowUpdateModal,
    setIsShowUpdateModal,
  } = useContext(ModalContext);

  const onToggleRemoveModal = () => {
    dispatch(
      domainId({
        id: id,
        domain_name: domain,
      })
    );

    return setIsShowRemoveModal(!isShowRemoveModal);
  };

  const onToggleUpdateModal = () => {
    dispatch(
      domainId({
        id: id,
        domain_name: domain,
      })
    );

    return setIsShowUpdateModal(!isShowUpdateModal);
  };

  return (
    <TableRow
      key={id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableBodyCell scope="row">
        <Typography variant="body1" component="span">
          {domain}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {groups}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {users}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="center" sx={{ width: '50px', p: '16px 0' }}>
        <IconButton
          onClick={onToggleUpdateModal}
          className={appStyles['MuiIconButton-root']}
        >
          <Edit />
        </IconButton>
      </TableBodyCell>
      <TableBodyCell align="center" sx={{ width: '50px', p: '16px 0' }}>
        <IconButton
          onClick={onToggleRemoveModal}
          className={appStyles['MuiIconButton-root']}
        >
          <Delete />
        </IconButton>
      </TableBodyCell>
    </TableRow>
  );
};

export default DomainsListItem;

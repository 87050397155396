import { getAuth, getAuthSuccess, getAuthFailure } from '../store/authReducer';
import { api } from '../api/httpClient';

export const fetchAuthData = () => {
  return function (dispatch) {
    dispatch(getAuth());

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/auth/rest_api/v1/users/is_authenticate/`
      )
      .then((response) => {
        if (response.data.is_authenticated) {
          dispatch(getAuthSuccess(response.data));
        } else {
          window.location.replace(
            `${process.env.REACT_APP_BASE_URL}/auth/login/`
          );
        }
      })
      .catch(() => {
        dispatch(getAuthFailure());
        window.location.replace(
          `${process.env.REACT_APP_BASE_URL}/auth/login/`
        );
      });
  };
};

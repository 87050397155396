import { SvgIcon } from '@mui/material';

const Edit = () => (
  <SvgIcon>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5192 2.36881C15.2944 2.14399 14.9298 2.14399 14.705 2.36881L2.36857 14.7053C2.26061 14.8132 2.19995 14.9597 2.19995 15.1123V19.2245C2.19995 19.5424 2.4577 19.8002 2.77565 19.8002H6.8878C7.04049 19.8002 7.18692 19.7395 7.29488 19.6316L19.6313 7.29513C19.8562 7.0703 19.8562 6.70579 19.6313 6.48096L15.5192 2.36881ZM3.35135 18.6488V15.3508L10.5888 8.1134C10.5901 8.11479 10.5915 8.11618 10.5929 8.11756L13.8826 11.4073C13.884 11.4087 13.8854 11.41 13.8867 11.4114L6.64934 18.6488H3.35135ZM14.7009 10.5973L18.4101 6.88805L15.1121 3.59006L11.4029 7.29928C11.4043 7.30064 11.4057 7.30201 11.407 7.30339L14.6968 10.5931C14.6981 10.5945 14.6995 10.5959 14.7009 10.5973Z"
        fill="#334D6E"
      />
    </svg>
  </SvgIcon>
);

export default Edit;

import {
  updateUsersOnScroll,
  getUsersListFailure,
} from '../store/usersReducer';
import { api } from '../api/httpClient';

export const fetchUsersOnScroll = (userId, pageCount) => {
  return function (dispatch) {
    api
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/ad/rest_api/v1/users/${userId}/ad_users/?page=${
          pageCount || 1
        }&page_size=50`
      )
      .then((response) => {
        dispatch(updateUsersOnScroll(response.data.results));
      })
      .catch(() => {
        dispatch(getUsersListFailure());
      });
  };
};

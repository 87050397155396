import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from './authReducer';
import cabinetMenuReducer from './cabinetMenuReducer';
import profilesReducer from './profilesReducer';
import usersReducer from './usersReducer';
import domainsReducer from './domainsReducer';
import collectionsReducer from './collectionsReducer';
import groupsReducer from './groupsReducer';
import notificationReducer from './notificationReducer';
import treeReducer from './treeReducer';
import thunk from 'redux-thunk';

const roorReducer = combineReducers({
  auth: authReducer,
  cabinetMenu: cabinetMenuReducer,
  profiles: profilesReducer,
  users: usersReducer,
  domains: domainsReducer,
  collections: collectionsReducer,
  groups: groupsReducer,
  notification: notificationReducer,
  tree: treeReducer,
});

export const store = createStore(
  roorReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const GET_AUTH = 'GET_AUTH';
const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';
const GET_AUTH_FAILURE = 'GET_AUTH_FAILURE';

const initialState = {
  auth: [],
  loading: true,
  error: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case GET_AUTH:
      return {
        ...state,
        loading: true,
      };
    case GET_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        auth: action.payload,
      };
    case GET_AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        auth: [],
      };
    default:
      return state;
  }
}

export const getAuth = () => ({
  type: GET_AUTH,
});

export const getAuthSuccess = (auth) => ({
  type: GET_AUTH_SUCCESS,
  payload: auth,
});

export const getAuthFailure = (error) => ({
  type: GET_AUTH_FAILURE,
  payload: error,
});

import {
  getProfilesSuccess,
  getProfilesFailure,
} from '../store/profilesReducer';
import { api } from '../api/httpClient';

export const fetchProfilesList = (user_id) => {
  return function (dispatch) {
    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/cabinet/rest_api/v1/users/${user_id}/profiles/`
      )
      .then((response) => {
        dispatch(getProfilesSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getProfilesFailure());
      });
  };
};

import React from 'react';
import { useSelector } from 'react-redux';
import { TableRow, Typography } from '@mui/material';

import { TableBodyCell } from '../table-cells/TableCells';

import { uid } from '../../uid';

const UsersListItem = ({
  id,
  user_name,
  collections,
  policy,
  pc,
  os,
  last_seen,
}) => {
  const profilesList = useSelector((state) => state.profiles.profiles);

  return (
    <TableRow
      key={id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableBodyCell scope="row">
        <Typography variant="h2" component="span">
          {user_name}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {collections}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        {profilesList.map((profile) =>
          profile.id === policy ? (
            <Typography key={uid()} variant="body1" component="span">
              {profile.name}
            </Typography>
          ) : (
            ''
          )
        )}
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {pc}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {os}
        </Typography>
      </TableBodyCell>
      <TableBodyCell align="left">
        <Typography variant="body1" component="span">
          {last_seen}
        </Typography>
      </TableBodyCell>
    </TableRow>
  );
};

export default UsersListItem;

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const RadioButtonsGroup = ({ label, defaultValue, buttons, sx, onChange }) => {
  return (
    <FormControl
      // Used inline styles because it's unique case of FormControl
      sx={{
        ...sx,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 3,
      }}
    >
      <FormLabel
        id="radio-buttons-group-label"
        // Hardcoded color because didn't get how to import it with palette
        sx={{ color: '#141c24', fontWeight: 500 }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-labelledby="radio-buttons-group-label"
        defaultValue={defaultValue}
        name="radio-buttons-group"
        row
        onChange={onChange}
      >
        {buttons.map((button, index) => (
          <FormControlLabel
            key={`${index}-radio`}
            value={button.value}
            control={<Radio />}
            label={button.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;

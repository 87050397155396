import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Stack, Paper, Button } from '@mui/material';

import Search from '../UI/search/Search';
import CollectionsList from './CollectionsList';
import CollectionsEmptyCreate from './CollectionsEmptyCreate';
import FetchError from '../UI/fetch-error/FetchError';
import Loader from '../UI/loader/Loader';

import { searchCollection } from '../../store/collectionsReducer';

const Collections = () => {
  const { error, loading } = useSelector((state) => state.collections);
  let collections = useSelector((state) => state.collections.collections_list);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <FetchError />;
  }

  if (collections.length === 0) {
    return <CollectionsEmptyCreate />;
  }

  return (
    <Paper>
      <>
        <Stack
          gap={2}
          justifyContent={{ md: 'space-between' }}
          alignItems={{ md: 'flex-start' }}
        >
          <Search placeholder="Найти коллекцию" cb={searchCollection} />
          <Button
            to="/collections/create"
            variant="contained"
            size="small"
            component={NavLink}
          >
            Добавить новую коллекцию
          </Button>
        </Stack>
        <CollectionsList />
      </>
    </Paper>
  );
};

export default Collections;

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Paper, Grid, Button, Dialog, Typography, Box } from '@mui/material';

import { notificationSuccess } from '../../store/notificationReducer';
import { collectionUpdate } from '../../store/collectionsReducer';
import ModalContext from '../../contexts/modal-context';

import TextField from '../text-field/TextField';
import Select from '../select/Select';
import ArrowButton from '../UI/go-back/GoBack';
import CollectionsConfigurate from './CollectionsConfigurate';
import Notification from '../UI/notification/Notification';

import success from '../../icons/success.svg';

import './Collections.scss';
import appStyles from '../app/App.module.scss';

const CollectionsEditForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const isAuthData = useSelector((state) => state.auth.auth);
  const profilesList = useSelector((state) => state.profiles.profiles);
  const checkedCollectionItems = useSelector(
    (state) => state.collections.checked_items
  );
  const collectionsDataEdit = useSelector((state) =>
    state.collections.collections_list.filter((e) => e.id.toString() === id)
  )[0];

  const { isShowCollectionModal, setIsShowCollectionModal } =
    useContext(ModalContext);

  const [name, setName] = useState(
    collectionsDataEdit && collectionsDataEdit.name
  );
  const [nameIsBlur, setNameIsBlur] = useState(false);
  const [nameError, setNameError] = useState(
    'Collection name field is required'
  );
  const [policy, setPolicy] = useState(
    collectionsDataEdit && collectionsDataEdit.profile
  );
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsSuccess, setFormIsSuccess] = useState(false);
  const [updateCollectionData, setCreateCollectionData] = useState({
    plan_code: 'RESELLER',
    priority: collectionsDataEdit && collectionsDataEdit.priority,
    profile: collectionsDataEdit && collectionsDataEdit.profile,
    domains: [],
    groups: [],
    ad_users: collectionsDataEdit && collectionsDataEdit.ad_users,
  });

  useEffect(() => {
    collectionsDataEdit && setCheckedUsers(collectionsDataEdit.ad_users);
  }, [collectionsDataEdit]);

  useEffect(() => {
    nameError ? setFormIsValid(false) : setFormIsValid(true);
  }, [nameError]);

  useEffect(() => {
    setCreateCollectionData({
      ...updateCollectionData,
      ad_users: checkedCollectionItems,
    });
    setFormIsValid(true);

    // eslint-disable-next-line
  }, [checkedCollectionItems]);

  const nameHandler = (e) => {
    setName(e.target.value);

    if (!e.target.value) {
      setNameError('Name field is required');
    } else {
      setNameError('');
      setCreateCollectionData({
        ...updateCollectionData,
        name: e.target.value,
      });
    }
  };

  const policyHandler = (e) => {
    setPolicy(e.target.value);

    setCreateCollectionData({
      ...updateCollectionData,
      profile: e.target.value,
    });
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name':
        setNameIsBlur(true);
        break;
      default:
        break;
    }
  };

  //edit collection
  const onUpdateCollection = (data) => {
    axios({
      method: 'PATCH',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${isAuthData.user_id}/collections/${id}/`,
      withCredentials: true,
      data: data,
    })
      .then(() => {
        dispatch(collectionUpdate(updateCollectionData));
        setIsShowCollectionModal(!isShowCollectionModal);
      })
      .catch((error) => {
        const notification_error = {
          isNotification: true,
          type: 'error',
          action: 'error',
          message: error.response.data[0] || error.response.data.priority[0],
        };

        dispatch(notificationSuccess(notification_error));
      });
  };

  const formSend = (e) => {
    e.preventDefault();

    setFormIsSuccess(!formIsSuccess);
    setFormIsValid(false);

    onUpdateCollection(updateCollectionData);

    setNameError(' ');
  };

  const onToggleCollectionModal = () => {
    navigate('/collections');
    return setIsShowCollectionModal(false);
  };

  const successCircleCls = appStyles['circle'] + ' ' + appStyles['success'];

  return (
    collectionsDataEdit && (
      <div className="collections-form-wrapper">
        <ArrowButton
          url="/collections"
          direction="left"
          title="Создание новой коллекции"
        />
        <Paper sx={{ position: 'relative' }}>
          <Grid container spacing={1} pb={3} component="form">
            <Grid item xs={12} md={3}>
              <TextField
                value={name}
                size="small"
                placeholder="Название коллекции"
                onChange={nameHandler}
                onBlur={blurHandler}
              />
              {nameIsBlur && nameError && (
                <span className="validate-error">{nameError}</span>
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <Select value={policy} onChange={policyHandler}>
                {profilesList.map((option) => (
                  <option key={Math.random()} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </Grid>
          </Grid>

          <CollectionsConfigurate
            collectionName={name}
            collectionUsers={checkedUsers}
            collectionData={collectionsDataEdit}
          />
          <div className="collections-form-create">
            <Button
              variant="contained"
              disabled={formIsValid ? false : true}
              size="small"
              onClick={formSend}
            >
              Сохранить коллекцию
            </Button>
          </div>
          <Notification />
        </Paper>

        <Dialog open={isShowCollectionModal}>
          <Box className={successCircleCls} sx={{ alignSelf: 'center' }}>
            <img
              src={success}
              width={28}
              height={28}
              alt="Collection Success"
            />
          </Box>
          <Typography
            variant="subtitle2"
            component="h3"
            sx={{ pt: '12px', pb: 3 }}
          >
            Коллекция изменена
          </Typography>
          <ul className="collections-create-success-list">
            <li>{name}</li>
            <li>{policy}</li>
            <li>{checkedCollectionItems.length} Users</li>
          </ul>
          <Button
            variant="contained"
            size="small"
            onClick={onToggleCollectionModal}
          >
            Got it
          </Button>
        </Dialog>
      </div>
    )
  );
};

export default CollectionsEditForm;

import { SvgIcon } from '@mui/material';

const Logout = () => (
  <SvgIcon>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.1665 1.6665H13.3332C13.9962 1.6665 14.6321 1.9299 15.1009 2.39874C15.5698 2.86758 15.8332 3.50346 15.8332 4.1665V15.8332C15.8332 16.0542 15.7454 16.2661 15.5891 16.4224C15.4328 16.5787 15.2209 16.6665 14.9998 16.6665H12.4998"
        stroke="#A6B1C1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.1665 1.6665L10.4898 2.9315C11.0565 3.04477 11.5664 3.35084 11.9329 3.79767C12.2993 4.24449 12.4997 4.80446 12.4998 5.38234V17.3165C12.4998 17.4397 12.4724 17.5614 12.4196 17.6728C12.3668 17.7842 12.29 17.8825 12.1947 17.9606C12.0993 18.0387 11.9879 18.0947 11.8683 18.1245C11.7487 18.1544 11.624 18.1573 11.5032 18.1332L5.5065 16.9348C5.12854 16.8593 4.78844 16.6551 4.54412 16.357C4.2998 16.0589 4.16635 15.6853 4.1665 15.2998V1.6665Z"
        stroke="#A6B1C1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 10V11.6667"
        stroke="#A6B1C1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default Logout;

import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Grid } from '@mui/material';

import AuthContext from '../../contexts/authContext';
import { uid } from '../../uid';

import styles from './SubMenu.module.scss';

const SubMenu = () => {
  const { menuAdItem } = useContext(AuthContext);
  const subMenuItems = menuAdItem.find((item) =>
    window.location.pathname.includes(item.name)
  ).children;

  return (
    <Grid container spacing={2} sx={{ p: '16px 0 24px' }}>
      {subMenuItems.map((item) => (
        <Grid item xs={4} key={uid()}>
          <NavLink
            to={item.url}
            className={({ isActive }) => {
              const activeCls = isActive ? 'active' : '';

              return `${styles['tab-item']} ${styles[activeCls]}`;
            }}
          >
            <Typography variant="body1" component="span">
              {item.title}
            </Typography>
          </NavLink>
        </Grid>
      ))}
    </Grid>
  );
};

export default SubMenu;

import {
  getCollectionsList,
  getCollectionsListSuccess,
  getCollectionsListFailure,
} from '../store/collectionsReducer';
import { api } from '../api/httpClient';

export const fetchCollectionsList = (user_id) => {
  return function (dispatch) {
    dispatch(getCollectionsList());

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/collections/`
      )
      .then((response) => {
        dispatch(getCollectionsListSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getCollectionsListFailure());
      });
  };
};

export default function findUsersInOtherGroups(GROUPS, userId) {
  const itemsToCheckState = {};

  GROUPS.forEach((group) => {
    if (group.ad_users.includes(+userId)) {
      const idForCheckedState = JSON.stringify({
        ad_users: `d${group.domain}-g${group.id}-u${userId}`,
      });

      itemsToCheckState[idForCheckedState] = null;
    }
  });

  return itemsToCheckState;
}

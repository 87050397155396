import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, InputBase, IconButton } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const Search = ({ placeholder, cb }) => {
  const [search, setSearch] = useState('');
  const dispatch = useDispatch();

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };

  const clearField = () => {
    setSearch('');
  };

  useEffect(() => {
    dispatch(cb(search));
  }, [search, cb, dispatch]);

  return (
    <Grid
      container
      sx={{
        pt: '4px',
        pb: '4px',
        borderTop: '1px solid #ebeff2',
        borderBottom: '1px solid #ebeff2',
      }}
    >
      <IconButton>
        <SearchIcon aria-label="search" color="info" />
      </IconButton>
      <InputBase
        id="search-field"
        placeholder={placeholder}
        value={search}
        onChange={searchHandler}
        sx={{ flex: '1 0' }}
      />

      <IconButton onClick={clearField} color="info">
        <CloseIcon aria-label="clear" />
      </IconButton>
    </Grid>
  );
};

export default Search;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { setCollectionPriority } from '../../store/collectionsReducer';

import usePagination from '../../hooks/usePagination';
import Paginator from '../UI/paginator/Paginator';
import CollectionsListItem from './CollectionsListItem';
import RemoveCollection from './RemoveCollection';
import FetchError from '../UI/fetch-error/FetchError';
import { TableHeadCell } from '../table-cells/TableCells';

import './Collections.scss';

const CollectionsList = () => {
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);
  const { error } = useSelector((state) => state.collections);
  const searchResult = useSelector((state) => state.collections.search);
  let collections = useSelector((state) => state.collections.collections_list);
  let changePriority = [];

  const initialDragAndDropState = {
    draggedFrom: null,
    draggedTo: null,
    isDragging: false,
    originalOrder: [],
    updatedOrder: [],
  };
  const [dragAndDrop, setDragAndDrop] = useState(initialDragAndDropState);

  let [collectionsList, setCollectionsList] = useState(
    collections.sort((a, b) => a.priority - b.priority)
  );

  useEffect(() => {
    changePriority = [];

    collectionsList.forEach((item, index) => {
      changePriority.push({
        collection_id: item.id,
        priority: index + 1,
      });
    });

    // console.log(changePriority);
  }, [collectionsList]);

  useEffect(() => {
    setCollectionsList(collections);
  }, [collections]);

  const onChangePriority = () => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${isAuthData.user_id}/collections/priorities/`,
      withCredentials: true,
      data: {
        data: changePriority,
      },
    })
      .then(() => {
        dispatch(setCollectionPriority(changePriority));
      })
      .catch(() => {
        console.log('error');
      });
  };

  const onDragStart = (e) => {
    const initialPosition = Number(e.currentTarget.dataset.position);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: collectionsList,
    });

    e.dataTransfer.setData('text/html', '');
  };

  const onDragOver = (e) => {
    e.preventDefault();

    let newList = dragAndDrop.originalOrder;

    const draggedFrom = dragAndDrop.draggedFrom;
    const draggedTo = Number(e.currentTarget.dataset.position);
    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter(
      (item, index) => index !== draggedFrom
    );

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo),
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo,
      });
    }
  };

  const onDrop = (e) => {
    setCollectionsList(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false,
    });
  };

  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null,
    });
  };

  const itemsCount = 5;
  const {
    // firstContentIndex,
    // lastContentIndex,
    nextPage,
    prevPage,
    page,
    gaps,
    setPage,
    totalPages,
  } = usePagination({
    contentPerPage: itemsCount,
    count: collectionsList && collectionsList.length,
  });

  if (searchResult.length > 0) {
    collectionsList = collectionsList.filter((item) => {
      return item.name
        .toLocaleLowerCase()
        .match(searchResult.toLocaleLowerCase());
    });
  }

  const collectionItem = (
    id,
    priority,
    name,
    policy,
    users,
    dataposition,
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    onChangePriority
  ) => {
    return (
      <CollectionsListItem
        key={id}
        id={id}
        priority={priority}
        name={name}
        policy={policy}
        users={users}
        dataposition={dataposition}
        onDragStart={onDragStart}
        onDragOver={onDragOver}
        onDrop={onDrop}
        onDragLeave={onDragLeave}
        onChangePriority={onChangePriority}
      />
    );
  };

  if (error) {
    return <FetchError />;
  }

  if (collectionsList.length === 0) {
    return <p className="collections-list-empty">No Collections yet</p>;
  }

  return (
    <React.Fragment>
      <TableContainer sx={{ pt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell align="center">
                <Typography variant="h2" component="span">
                  Приоритет
                </Typography>
              </TableHeadCell>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Коллекции
                </Typography>
              </TableHeadCell>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Профиль
                </Typography>
              </TableHeadCell>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Пользователи
                </Typography>
              </TableHeadCell>
              <TableHeadCell></TableHeadCell>
              <TableHeadCell></TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collectionsList?.map((listItem, index) =>
              collectionItem(
                listItem.id,
                listItem.priority,
                listItem.name,
                listItem.profile,
                listItem.ad_users.length,
                index,
                onDragStart,
                onDragOver,
                onDrop,
                onDragLeave,
                onChangePriority
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {collectionsList.length > itemsCount && (
        <Paginator
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
          gaps={gaps}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}

      <RemoveCollection />
    </React.Fragment>
  );
};

export default CollectionsList;

import { SvgIcon } from '@mui/material';

const Delete = ({ sx }) => (
  <SvgIcon sx={sx}>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0074 8.55078C13.3581 8.55078 13.6424 8.8351 13.6424 9.18583V15.5363C13.6424 15.8871 13.3581 16.1714 13.0074 16.1714C12.6566 16.1714 12.3723 15.8871 12.3723 15.5363V9.18583C12.3723 8.8351 12.6566 8.55078 13.0074 8.55078Z"
        fill="#334D6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37846 8.55078C9.72919 8.55078 10.0135 8.8351 10.0135 9.18583V15.5363C10.0135 15.8871 9.72919 16.1714 9.37846 16.1714C9.02773 16.1714 8.74341 15.8871 8.74341 15.5363V9.18583C8.74341 8.8351 9.02773 8.55078 9.37846 8.55078Z"
        fill="#334D6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1145 5.55693C5.1145 5.2062 5.39882 4.92188 5.74955 4.92188H16.6362C16.9869 4.92188 17.2712 5.2062 17.2712 5.55693V18.258C17.2712 19.1097 16.5807 19.8002 15.7289 19.8002H6.65677C5.805 19.8002 5.1145 19.1097 5.1145 18.258V5.55693ZM6.3846 6.19198V18.258C6.3846 18.4083 6.50646 18.5301 6.65677 18.5301H15.7289C15.8792 18.5301 16.0011 18.4083 16.0011 18.258V6.19198H6.3846Z"
        fill="#334D6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.30005 5.55693C3.30005 5.2062 3.58437 4.92188 3.9351 4.92188H18.4506C18.8013 4.92188 19.0856 5.2062 19.0856 5.55693C19.0856 5.90766 18.8013 6.19198 18.4506 6.19198H3.9351C3.58437 6.19198 3.30005 5.90766 3.30005 5.55693Z"
        fill="#334D6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.92896 3.74246C6.92896 2.89069 7.61945 2.2002 8.47122 2.2002H13.9145C14.7663 2.2002 15.4568 2.89069 15.4568 3.74246V5.5569C15.4568 5.90763 15.1725 6.19195 14.8217 6.19195H7.56401C7.21328 6.19195 6.92896 5.90763 6.92896 5.5569V3.74246ZM8.47122 3.4703C8.32091 3.4703 8.19906 3.59215 8.19906 3.74246V4.92184H14.1867V3.74246C14.1867 3.59215 14.0648 3.4703 13.9145 3.4703H8.47122Z"
        fill="#334D6E"
      />
    </svg>
  </SvgIcon>
);

export default Delete;

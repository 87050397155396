import {
  getDomainsList,
  getDomainsListSuccess,
  getDomainsListFailure,
} from '../store/domainsReducer';
import { api } from '../api/httpClient';

export const fetchDomainsList = (user_id) => {
  return function (dispatch) {
    dispatch(getDomainsList());

    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${user_id}/agent_domains/`
      )
      .then((response) => {
        dispatch(getDomainsListSuccess(response.data.results));
      })
      .catch(() => {
        dispatch(getDomainsListFailure());
      });
  };
};

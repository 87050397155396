import { getAuthKeySuccess, getAuthKeyFailure } from '../store/usersReducer';
import { api } from '../api/httpClient';

export const fetchAuthKeyAd = (userId) => {
  return function (dispatch) {
    api
      .get(
        `${process.env.REACT_APP_BASE_URL}/agents/rest_api/v1/users/${userId}/auth_key/`
      )
      .then((response) => {
        dispatch(getAuthKeySuccess(response.data.auth_key));
      })
      .catch(() => {
        dispatch(getAuthKeyFailure());
      });
  };
};

import React from 'react';
import { Grid, Button, Typography } from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import './Paginator.scss';

const Paginator = ({ nextPage, prevPage, page, gaps, setPage, totalPages }) => {
  return (
    <Grid container sx={{ pt: 2, pb: 2, justifyContent: 'center' }}>
      <div className="paginator">
        <Button
          onClick={prevPage}
          variant="outlined"
          sx={{ borderColor: 'info.main' }}
          className={`previous ${page === 1 ? 'disabled' : ''}`}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        <Button
          onClick={() => setPage(1)}
          className={`page ${page === 1 ? 'active' : ''}`}
        >
          <Typography variant="subtitle2" component="span">
            1
          </Typography>
        </Button>
        {gaps.before ? '...' : null}
        {gaps.paginationGroup.map((el) => (
          <Button
            onClick={() => setPage(el)}
            key={el}
            className={`page ${page === el ? 'active' : ''}`}
          >
            <Typography variant="subtitle2" component="span">
              {el}
            </Typography>
          </Button>
        ))}
        {gaps.after ? '...' : null}
        <Button
          onClick={() => setPage(totalPages)}
          className={`page ${page === totalPages ? 'active' : ''}`}
        >
          <Typography variant="subtitle2" component="span">
            {totalPages}
          </Typography>
        </Button>
        <Button
          onClick={nextPage}
          variant="outlined"
          className={`next ${page === totalPages ? 'disabled' : ''}`}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </div>
    </Grid>
  );
};

export default Paginator;

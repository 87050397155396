import MuiCheckbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import styles from './Checkbox.module.scss';
import { SvgIcon } from '@mui/material';

function Icon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 1.4H5C3.01178 1.4 1.4 3.01178 1.4 5V15C1.4 16.9882 3.01178 18.6 5 18.6H15C16.9882 18.6 18.6 16.9882 18.6 15V5C18.6 3.01178 16.9882 1.4 15 1.4ZM5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5Z"
          fill="#C2CFE0"
        />
      </svg>
    </SvgIcon>
  );
}

function CheckedIcon() {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 1.4H5C3.01178 1.4 1.4 3.01178 1.4 5V15C1.4 16.9882 3.01178 18.6 5 18.6H15C16.9882 18.6 18.6 16.9882 18.6 15V5C18.6 3.01178 16.9882 1.4 15 1.4ZM5 0C2.23858 0 0 2.23858 0 5V15C0 17.7614 2.23858 20 5 20H15C17.7614 20 20 17.7614 20 15V5C20 2.23858 17.7614 0 15 0H5Z"
          fill="#07A9ED"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0624 6.96856C15.3481 7.22447 15.3867 7.68087 15.1486 7.98796L9.8666 14.8008L5.50201 10.1095C5.23903 9.82686 5.23903 9.36858 5.50201 9.08592C5.76498 8.80326 6.19135 8.80326 6.45433 9.08592L9.7761 12.6563L14.114 7.06123C14.3521 6.75414 14.7767 6.71265 15.0624 6.96856Z"
          fill="#07A9ED"
        />
      </svg>
    </SvgIcon>
  );
}

const Checkbox = (props) => {
  return (
    <FormControlLabel
      classes={{
        label: styles['MuiFormControlLabel-label'],
      }}
      {...props}
      control={
        <MuiCheckbox
          classes={{ root: styles['MuiFormControlLabel-root'] }}
          icon={<Icon />}
          checkedIcon={<CheckedIcon />}
        />
      }
    />
  );
};

export default Checkbox;

import { FormControl, InputLabel, InputBase } from '@mui/material';

import styles from './TextField.module.scss';

const TextField = ({
  id,
  label,
  value,
  name,
  size,
  placeholder,
  onChange,
  onBlur,
}) => {
  return (
    <FormControl variant="standard">
      {label ? (
        <InputLabel shrink htmlFor={id} sx={{ fontWeight: 600 }}>
          {label}
        </InputLabel>
      ) : null}

      <InputBase
        id={id}
        size={size}
        classes={{
          root: styles['MuiInputBase-root'],
          input: styles['MuiInputBase-input'],
          sizeSmall: styles['MuiInputBase-sizeSmall'],
        }}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
      />
    </FormControl>
  );
};

export default TextField;

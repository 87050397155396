import { Stack, Grid, Button, Typography, Box, Paper } from '@mui/material';
import { Link } from 'react-router-dom';

import img from '../../icons/no-collections.svg';

const CollectionsEmptyCreate = () => {
  return (
    <Paper>
      <Stack
        direction={{ md: 'row' }}
        gap={3}
        alignItems={{ md: 'center' }}
        justifyContent={{ md: 'space-between' }}
      >
        <Grid item xs={12} md={6}>
          <Stack alignItems="flex-start">
            <Typography
              sx={{
                pb: 2,
                fontFamily: 'Roboto-500',
                fontWeight: 500,
                color: 'info.main',
              }}
            >
              Коллекция — это способ группировки <br /> пользователей в Active
              Directory. Создайте <br /> свою первую коллекцию и выберите для{' '}
              <br />
              нее политику фильтрации.
            </Typography>
            <Button
              to="/"
              component={Link}
              variant="text"
              color="secondary"
              sx={{ p: '0 !important' }}
            >
              <Typography
                sx={{
                  fontFamily: 'Roboto-500',
                  fontWeight: 500,
                  lineHeight: '24px',
                  color: 'primary.main',
                }}
              >
                Инструкция Active Directory
              </Typography>
            </Button>
            <Box sx={{ pt: 4 }} />
            <Button
              component={Link}
              variant="contained"
              to="/collections/create"
            >
              Создать
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box maxWidth={414}>
            <img
              src={img}
              width="100%"
              height="auto"
              alt="no collections yet"
            />
          </Box>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default CollectionsEmptyCreate;

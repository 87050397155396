import { setFilteredUsersFromApi } from '../store/usersReducer';

import { api } from '../api/httpClient';

export const fetchFilteredUsersList = (userId, groupId, debouncedText) => {
  return function (dispatch) {
    const baseUrl = process.env.REACT_APP_BASE_URL;

    api
      .get(
        `${baseUrl}/ad/rest_api/v1/users/${userId}/ad_groups/${groupId}/ad_users/?include_nested_groups=1&search=${debouncedText}`
      )
      .then((response) => {
        dispatch(setFilteredUsersFromApi(response.data.results));
      });
  };
};

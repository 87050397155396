import React from 'react';

import './FetchError.scss';

const FetchError = () => {
  return (
    <div className="fetch-error">
      <div className="fetch-error-img" />
      <p className="fetch-error-text">Что-то пошло не так.</p>
      <p className="fetch-error-text">Пожалуйста, попробуйте немного позже</p>
    </div>
  );
};

export default FetchError;

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const ArrowButton = ({ url, title }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pb: 3 }}>
      <Button
        component={NavLink}
        to={url}
        startIcon={
          <ArrowBackIosNewIcon sx={{ fontSize: '16px', color: 'info.main' }} />
        }
      >
        <Typography
          variant="h2"
          component="span"
          sx={{ lineHeight: '24px', color: 'info.main' }}
        >
          {title}
        </Typography>
      </Button>
    </Box>
  );
};

export default ArrowButton;

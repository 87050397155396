import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Grid,
  Dialog,
  Button,
  Typography,
  Box,
  Stack,
} from '@mui/material';

import Select from '../select/Select';
import TextField from '../text-field/TextField';
import ArrowButton from '../UI/go-back/GoBack';
import Notification from '../UI/notification/Notification';

import { addCollection } from '../../store/collectionsReducer';
import { notificationSuccess } from '../../store/notificationReducer';

import ModalContext from '../../contexts/modal-context';
import CollectionsConfigurate from './CollectionsConfigurate';

import './Collections.scss';
import appStyles from '../app/App.module.scss';

import success from '../../icons/success.svg';

const CollectionsForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthData = useSelector((state) => state.auth.auth);
  const profilesList = useSelector((state) => state.profiles.profiles);
  const checkedCollectionItems = useSelector(
    (state) => state.collections.checked_items
  );
  const collectionsList = useSelector(
    (state) => state.collections.collections_list
  );

  const { isShowCollectionModal, setIsShowCollectionModal } =
    useContext(ModalContext);

  const [name, setName] = useState('');
  const [nameIsBlur, setNameIsBlur] = useState(false);
  const [nameError, setNameError] = useState(
    'Collection name field is required'
  );
  const [policy, setPolicy] = useState('');

  const [initialPolicy, setInitialPolicy] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsSuccess, setFormIsSuccess] = useState(false);
  const [createCollectionData, setCreateCollectionData] = useState({
    plan_code: 'RESELLER',
    priority: collectionsList.length + 1,
    profile: policy,
    domains: [],
    groups: [],
  });

  useEffect(() => {
    nameError ? setFormIsValid(false) : setFormIsValid(true);
  }, [nameError]);

  useEffect(() => {
    // Find and set default policy
    let defaultProfileId = 0;

    if (profilesList.length > 0) {
      defaultProfileId = profilesList.filter((option) => option.is_default)[0]
        .id;
    }

    setInitialPolicy(defaultProfileId);
  }, [profilesList]);

  useEffect(() => {
    // Create initial data for a new collection
    setCreateCollectionData((d) => ({
      ...d,
      ad_users: checkedCollectionItems,
      profile: initialPolicy,
    }));
  }, [checkedCollectionItems, initialPolicy]);

  const nameHandler = (e) => {
    setName(e.target.value);

    if (!e.target.value) {
      setNameError('Name field is required');
    } else {
      setNameError('');
      setCreateCollectionData({
        ...createCollectionData,
        name: e.target.value,
      });
    }
  };

  const policyHandler = (e) => {
    setPolicy(e.target.value);
    setCreateCollectionData({
      ...createCollectionData,
      profile: +e.target.value,
    });
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name':
        setNameIsBlur(true);
        break;
      default:
        break;
    }
  };

  const onCreateCollection = (data) => {
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_BASE_URL}/ad/rest_api/v1/users/${isAuthData.user_id}/collections/`,
      withCredentials: true,
      data: data,
    })
      .then(() => {
        dispatch(addCollection(createCollectionData));
        setIsShowCollectionModal(!isShowCollectionModal);
      })
      .catch((error) => {
        const notification_error = {
          isNotification: true,
          type: 'error',
          action: 'error',
          message: error.response.data[0] || error.response.data.priority[0],
        };

        dispatch(notificationSuccess(notification_error));
      });
  };

  const formSend = (e) => {
    e.preventDefault();

    setFormIsSuccess(!formIsSuccess);
    setFormIsValid(false);

    onCreateCollection(createCollectionData);

    setNameError(' ');
  };

  const onToggleCollectionModal = () => {
    navigate('/collections');

    return setIsShowCollectionModal(false);
  };

  const successCircleCls = appStyles['circle'] + ' ' + appStyles['success'];

  return (
    <div className="collections-form-wrapper">
      <ArrowButton
        url="/collections"
        direction="left"
        title="Создание новой коллекции"
      />
      <Paper sx={{ position: 'relative' }}>
        <Grid container columnGap={1} sx={{ pb: 3 }}>
          <Grid item xs={12} md={3}>
            <TextField
              id="collection"
              value={name}
              size="small"
              placeholder="Название коллекции"
              onChange={nameHandler}
              onBlur={blurHandler}
            />
            {nameIsBlur && nameError && (
              <span className="validate-error">{nameError}</span>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <Select value={policy} onChange={policyHandler}>
              {profilesList.map((option) => (
                <option key={`key-${option.id}`} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Select>
          </Grid>
        </Grid>
        <CollectionsConfigurate collectionName={name} />
        <div className="collections-form-create">
          <Button
            type="submit"
            variant="contained"
            disabled={!formIsValid}
            size="small"
            onClick={formSend}
          >
            Создать коллекцию
          </Button>
        </div>
        <Notification />
      </Paper>
      <Dialog open={isShowCollectionModal}>
        <Box className={successCircleCls}>
          <img src={success} width={28} height={28} alt="Collection Success" />
        </Box>
        <Typography variant="h2" component="h2" sx={{ pt: '26px', pb: '10px' }}>
          Коллекция создана
        </Typography>
        <Stack
          component="ul"
          gap="10px"
          className="collections-create-success-list"
        >
          <Typography variant="body1" component="li">
            {name}
          </Typography>
          <Typography variant="body1" component="li">
            {policy || initialPolicy}
          </Typography>
          <Typography variant="body1" component="li">
            {checkedCollectionItems.length} пользователей
          </Typography>
        </Stack>
        <Button variant="contained" onClick={onToggleCollectionModal}>
          Закрыть
        </Button>
      </Dialog>
    </div>
  );
};

export default CollectionsForm;

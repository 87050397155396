import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RadioButtonsGroup from '../radio-button/RadioButtonsGroup';

import { assignedUsers } from '../../store/usersReducer';

const UsersAssignedFilter = () => {
  const [assigned, setAssigned] = useState([]);
  const [assignFilter, setAssignFilter] = useState('all');

  const dispatch = useDispatch();
  const collections = useSelector(
    (state) => state.collections.collections_list
  );

  useEffect(() => {
    let collectionsMap = [];

    collections.forEach((collection) => {
      collectionsMap.push(...collection.ad_users);
    });

    setAssigned(collectionsMap);
  }, [collections]);

  useEffect(() => {
    switch (assignFilter) {
      case 'assigned':
        dispatch(
          assignedUsers({
            filter: assignFilter,
            data: assigned,
          })
        );
        break;
      case 'unassigned':
        dispatch(
          assignedUsers({
            filter: assignFilter,
            // Also pass all users that assigned to collections, but with 'unnassigned' filter
            // They'll be filtered in UserList before render
            data: assigned,
          })
        );
        break;
      case 'all':
        dispatch(
          assignedUsers({
            filter: assignFilter,
            data: [],
          })
        );
        break;
      default:
        break;
    }
  }, [assignFilter, assigned, dispatch]);

  const assignHandler = (e) => {
    setAssignFilter(e.target.value);
  };

  return (
    <RadioButtonsGroup
      label="Показать:"
      defaultValue="all"
      buttons={[
        { value: 'all', label: 'Все' },
        { value: 'unassigned', label: 'Неназначенные' },
        { value: 'assigned', label: 'Назначенные' },
      ]}
      onChange={assignHandler}
    />
  );
};

export default UsersAssignedFilter;

import React from 'react';
import { SvgIcon } from '@mui/material';

import common from '../app/App.module.scss';
import styles from './Footer.module.scss';

import { ReactComponent as VKIcon } from '../../icons/vk.svg';

const Footer = () => {
  const thisYear = new Date().getFullYear();

  return (
    <footer className={styles['footer']}>
      <div className={styles['year']}>
        &copy; {thisYear} SkyDNS - Все права защишены
      </div>
      <div className={styles['footer-social']}>
        <a
          href="https://vk.com/skydns"
          className={styles['vk']}
          rel="noreferrer"
          aria-label="vk"
        >
          <SvgIcon component={VKIcon} />
          {/* <img src={vk} width={24} height={24} alt="vk" /> */}
          <span className={common['offscreen']}>VK</span>
        </a>
      </div>
    </footer>
  );
};

export default Footer;
